<template lang="html">
  <div class="row">

    <div class="col-lg-8 col-md-8 col-sm-12">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <label class="required">Tên địa điểm</label>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <el-input
                placeholder="Tên"
                v-model="form.name"
                :class="errors.has('name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="name"
                data-vv-as="Tên"></el-input>
              <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <label class="required">Địa chỉ</label>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <el-input
                placeholder="Địa chỉ"
                v-model="form.address"
                :class="errors.has('address')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="address"
                data-vv-as="Địa chỉ"></el-input>
              <span class="text-danger" v-if="errors.has('address')">{{ errors.first('address') }}</span>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12 col-lg-2 col-md-2">
              <label>SĐT</label>
            </div>
            <div class="col-sm-12 col-lg-10 col-md-10">
              <el-input
                placeholder="Số điện thoại"
                v-model="form.phone">
              </el-input>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12 sidebar-right">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group">
            <label>Hiển thị</label>
            <el-switch
              class="pull-right"
              v-model="form.display"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </div>

        </div>
      </div>

    </div>

    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>
  </div>
</template>

<script>

import { Input, Button, Message, Switch } from 'element-ui';

export default {
  components: {
    ElInput: Input,
    ElButton: Button,
    ElSwitch: Switch,
    Message
  },

  computed: {
    form: {
      get() {
        return this.$store.state.locationDetail
      },
      set(value) {}
    }
  },

  mounted() {
    this.$store.dispatch('setPageTitle', 'Cập nhật địa điểm');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Cập nhật',
      type: 'primary',
      icon: '',
      callback: this.save
    }])

    let id = this.$route.params.id;
    this.$store.dispatch('fetchLocationDetail', id);
  },

  methods: {
    cancel() {
      this.$router.push({name: 'AllLocations'});
    },
    save() {
      let self = this;
      this.$validator.validateAll().then(function (result) {
        if (result) {
          self.$store.dispatch('updateLocation', self.form).then(res => {
            if (res.success) {
              Message({
                message: 'Cập nhật thành công',
                type: 'success'
              });
            } else {
              if (res.errors) {
                for (let err in res.errors) {
                  Message({
                    message: res.errors[err][0],
                    type: 'error'
                  });
                }
              } else {
                Message({
                  message: res.message,
                  type: 'error'
                });
              }
            }
          }, error => {
            console.error(error);
          });
        }
      })
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.form = {};
    this.$store.state.locationDetail = [];
  }
}
</script>
